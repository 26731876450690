import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_8 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_subscriptions_export_modal"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_13 = ["value"]
const _hoisted_14 = {
  href: "#",
  class: "text-gray-600 text-hover-primary mb-1"
}
const _hoisted_15 = { class: "badge badge-light" }
const _hoisted_16 = {
  href: "#",
  class: "btn btn-sm btn-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_17 = { class: "svg-icon svg-icon-5 m-0" }
const _hoisted_18 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4",
  "data-kt-menu": "true"
}
const _hoisted_19 = { class: "menu-item px-3" }
const _hoisted_20 = { class: "menu-item px-3" }
const _hoisted_21 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("input", {
            type: "text",
            "data-kt-subscription-table-filter": "search",
            class: "form-control form-control-solid w-250px ps-14",
            placeholder: "Search Subscriptions"
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
            ]),
            _cache[2] || (_cache[2] = _createTextVNode(" Export "))
          ]),
          _createVNode(_component_router_link, {
            to: "/subscriptions/add-subscription",
            class: "btn btn-primary"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
              ]),
              _cache[3] || (_cache[3] = _createTextVNode(" Add Subscription "))
            ]),
            _: 1
          })
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "d-flex justify-content-end align-items-center d-none",
          "data-kt-subscription-table-toolbar": "selected"
        }, [
          _createElementVNode("div", { class: "fw-bolder me-5" }, [
            _createElementVNode("span", {
              class: "me-2",
              "data-kt-subscription-table-select": "selected_count"
            }),
            _createTextVNode(" Selected ")
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-danger",
            "data-kt-subscription-table-select": "delete_selected"
          }, " Delete Selected ")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_KTDatatable, {
        "table-data": _ctx.data,
        "table-header": _ctx.headerConfig,
        "enable-items-per-page-dropdown": false
      }, {
        "cell-checkbox": _withCtx(({ row: customer }) => [
          _createElementVNode("div", _hoisted_12, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: customer.id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedCustomers) = $event))
            }, null, 8, _hoisted_13), [
              [_vModelCheckbox, _ctx.checkedCustomers]
            ])
          ])
        ]),
        "cell-customer": _withCtx(({ row: customer }) => [
          _createVNode(_component_router_link, {
            to: "/subscriptions/view-subscription",
            href: "",
            class: "text-gray-800 text-hover-primary mb-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(customer.customer), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        "cell-status": _withCtx(({ row: customer }) => [
          _createElementVNode("a", _hoisted_14, [
            _createElementVNode("div", {
              class: _normalizeClass(`badge badge-light-${customer.color}`)
            }, _toDisplayString(customer.status), 3)
          ])
        ]),
        "cell-billing": _withCtx(({ row: customer }) => [
          _createElementVNode("div", _hoisted_15, _toDisplayString(customer.billing), 1)
        ]),
        "cell-product": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.product), 1)
        ]),
        "cell-createdDate": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.createdDate), 1)
        ]),
        "cell-actions": _withCtx(({ row: customer }) => [
          _createElementVNode("a", _hoisted_16, [
            _cache[5] || (_cache[5] = _createTextVNode("Actions ")),
            _createElementVNode("span", _hoisted_17, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_router_link, {
                to: "/apps/customers/customer-details",
                class: "menu-link px-3"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("View")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.deleteCustomer(customer.id)),
                class: "menu-link px-3"
              }, "Delete", 8, _hoisted_21)
            ])
          ])
        ]),
        _: 1
      }, 8, ["table-data", "table-header"])
    ])
  ]))
}